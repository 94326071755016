import { useContext, useEffect, useState } from 'react'
import { ReadOnlyContext } from './ReadOnlyContext'
import {
  BaseSelect,
  CommonSelectDefaultProps,
  CommonSelectProps,
  OptionType,
} from './components/BaseSelect'
import { Chip } from './components/Chip'

export interface Props<T> extends CommonSelectProps<T> {
  /** Used to determine if an option is selected, considering the current value. Uses strict equality by default. */
  getOptionSelected?: (option: T, value?: (Partial<T> | string)[]) => boolean
  /** Callback fired when the value changes. */
  onChange?: (selectedValue: T[]) => void
  /**
   * The value of the autocomplete.
   *
   * The value must have reference equality with the option in order to be selected. You can customize the equality
   * behavior with the `getOptionSelected` prop.
   */
  value?: (Partial<T> | string)[]
  classes?: any
  limitTags?: number
  renderTags?: any
  filterOptions?: (options: T[], state: any) => T[]
}

/**
 * A multiple select component.
 */
export function MultiSelect<T extends OptionType>({
  clearable,
  createCustomOption,
  classes,
  style,
  disabled,
  error,
  fullWidth,
  getOptionDisabled = (option) => option.disabled,
  getOptionLabel = (option) => option.name,
  groupBy,
  gutterBottom,
  helperText,
  label,
  large,
  searchable,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  required,
  renderOption,
  renderTags,
  value,
  limitTags,
  filterOptions,
}: Props<T>) {
  const [selectedValues, setSelectedValues] = useState(() => {
    if (value) {
      return options.filter((option) => value.indexOf(option.value) !== -1)
    }
    return []
  })

  useEffect(() => {
    if (value) {
      setSelectedValues(options.filter((option) => value.indexOf(option.value) !== -1))
    } else {
      setSelectedValues([])
    }
  }, [value])

  const renderTagsFn = (values: T[], getTagProps: any) => {
    return values.map((option: T, index: number) => {
      return (
        <Chip
          key={getOptionLabel(option)}
          label={getOptionLabel(option)}
          large={large}
          tagProps={getTagProps({ index })}
        />
      )
    })
  }

  const forceDisabled = useContext(ReadOnlyContext)

  return (
    <BaseSelect
      searchable={searchable}
      clearable={clearable}
      createCustomOption={createCustomOption}
      disabled={forceDisabled || disabled}
      customClasses={classes}
      style={style}
      error={error}
      fullWidth={fullWidth}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option) => {
        return selectedValues.some((selectedValue) => option.value === selectedValue.value)
      }}
      groupBy={groupBy}
      gutterBottom={gutterBottom}
      helperText={helperText}
      label={label}
      large={large}
      multiple
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      required={required}
      renderOption={renderOption}
      limitTags={limitTags}
      filterOptions={filterOptions}
      // @ts-ignore Typed incorrectly in Material-UI
      renderTags={renderTags || renderTagsFn}
      selectedValue={selectedValues}
      setSelectedValue={setSelectedValues}
      compact
    />
  )
}

MultiSelect.defaultProps = CommonSelectDefaultProps
