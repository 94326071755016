import { ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Theme } from '@mui/material'
import { Typography } from '../../../Typography'

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    marginTop: theme.spacing(-0.5),
  },
  suffix: {
    marginLeft: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
  prefix: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
  },
}))

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle?: ReactNode
  headerPrefix?: ReactNode
  titleSuffix?: ReactNode
}

export const PageTitle = ({ title, subtitle, headerPrefix, titleSuffix, ...others }: Props) => {
  const { classes } = useStyles()

  return (
    <div {...others}>
      <Box display="flex" alignItems="center">
        {headerPrefix && <div className={classes.prefix}>{headerPrefix}</div>}
        <Box display="flex" flexDirection="column">
          <Typography variant="h2" className={classes.title}>
            {title}
            {titleSuffix && <span className={classes.suffix}>{titleSuffix}</span>}
          </Typography>
          {subtitle && (
            <Typography variant="body2" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  )
}
