import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { HOME_SNAPSHOT_VIEW } from './BuildHomeSnapshotPanel'
import { gql, useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client'
import get from 'lodash/get'
import { ACTOR_AVATAR_FRAGMENT } from '../ActorAvatar'
import { useBuildUrl } from '../authenticatedRoutes'
import { createArrayUpdater, useNotifications } from '../util'

const BUILD_VIEW = gql`
  fragment buildView on Build {
    id
    branch {
      id
      name
      edgePermalinkUrl
      latestCompletedBuild {
        id
      }
    }
    environment {
      id
      name
      activeUrls
      activeBuild {
        id
      }
    }
    environmentVersion {
      id
      version
      configSource
      versionNotes
    }
    originalBuild {
      id
      number
      status
      permalinkStatus
      cloudAwsLambdaStatus
      environment {
        id
        name # for links
      }
    }
    homeSnapshot {
      ...homeSnapshotView
    }
    lambdaJobs {
      name
      payloadJson
    }
    edgeConfigurationFileAttached
    edgeFunctionsFileAttached
    preloadStarted
    number
    xdnVersion
    cloudRuntime
    permalinkUrl
    createdAt
    updatedAt
    finishedAt
    status
    permalinkStatus
    cloudAwsLambdaStatus
    commitUrl
    uploaded
    routerContents
  }
  ${HOME_SNAPSHOT_VIEW}
`

export const BUILD_LINK = gql`
  fragment buildLink on Build {
    id
    number
    environment {
      id
      name
    }
  }
`

export const getBuild = gql`
  query getBuild($buildId: ID!) {
    build(id: $buildId) {
      ...buildView
    }
  }
  ${BUILD_VIEW}
`

const getBuildByNum = gql`
  query buildByNum($organizationSlug: String!, $propertySlug: String!, $buildNumber: Int!) {
    buildByNum(
      organizationSlug: $organizationSlug
      propertySlug: $propertySlug
      buildNumber: $buildNumber
    ) {
      ...buildView
    }
  }
  ${BUILD_VIEW}
`

export const useGetBuildQuery = (variables) => {
  const useQueryResult = useQuery(getBuildByNum, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  const { data, subscribeToMore } = useQueryResult

  const build = data && data.buildByNum
  const buildId = build && build.id

  useEffect(() => {
    if (!build) return

    return subscribeToMore({
      document: buildUpdateSubscription,
      variables: { buildId: build.id },
    })
  }, [buildId])

  return useQueryResult
}

const buildStatusUpdatedSubscription = gql`
  subscription buildStatusUpdated($buildId: ID!) {
    build: buildStatusUpdated(buildId: $buildId) {
      status
      permalinkStatus
      cloudAwsLambdaStatus
    }
  }
`

export const useBuildStatusUpdatedSubscription = (buildId, options = {}) => {
  return useSubscription(buildStatusUpdatedSubscription, { variables: { buildId }, ...options })
}

const getBuildLogs = gql`
  query getBuild($buildId: ID!) {
    build(id: $buildId) {
      id
      logs
    }
  }
`

const buildLogsSubscription = gql`
  subscription buildLogsUpdated($buildId: ID!) {
    buildLogsUpdated(buildId: $buildId) {
      build {
        id
        logs
      }
    }
  }
`

export const useGetBuildLogs = (buildId) => {
  const useQueryResult = useQuery(getBuildLogs, {
    variables: { buildId },
  })

  const { subscribeToMore } = useQueryResult

  useEffect(() => {
    return subscribeToMore({
      document: buildLogsSubscription,
      variables: { buildId },
    })
  }, [buildId])

  return useQueryResult
}

const getBuildPreloadLogs = gql`
  query getBuild($buildId: ID!) {
    build(id: $buildId) {
      id
      buildPreloadLogs
    }
  }
`

const buildPreloadLogsSubscription = gql`
  subscription buildPreloadLogsUpdated($buildId: ID!) {
    buildPreloadLogsUpdated(buildId: $buildId) {
      build {
        id
        buildPreloadLogs
      }
    }
  }
`

export const useGetBuildPreloadLogs = (buildId) => {
  const useQueryResult = useQuery(getBuildPreloadLogs, {
    variables: { buildId },
  })

  const { subscribeToMore } = useQueryResult

  useEffect(() => {
    return subscribeToMore({
      document: buildPreloadLogsSubscription,
      variables: { buildId },
    })
  }, [buildId])

  return useQueryResult
}

export const promoteBuildToEnvironment = gql`
  mutation promoteBuildToEnvironment(
    $buildId: ID!
    $environmentId: ID!
    $isRollback: Boolean
    $purgeCache: Boolean
  ) {
    promoteBuildToEnvironment(
      buildId: $buildId
      environmentId: $environmentId
      isRollback: $isRollback
      purgeCache: $purgeCache
    ) {
      build {
        ...buildLink
      }
      userErrors {
        message
      }
    }
  }
  ${BUILD_LINK}
`
export const getBuildDownloadUrl = gql`
  query downloadBuild($buildId: ID!) {
    getBuildDownloadUrl(id: $buildId)
  }
`

const buildIdByHostname = gql`
  query buildIdByHostname($hostname: String!) {
    id: buildIdByHostname(hostname: $hostname)
  }
`

export const useReviveBuildByHostname = (hostname) => {
  const [mutationError, setMutationError] = useState()
  const [revivingBuild, setRevivingBuild] = useState()
  const { data, loading: loadingBuildId } = useQuery(buildIdByHostname, { variables: { hostname } })
  const buildId = data?.id
  const buildNotFound = !loadingBuildId && !buildId

  const reviveBuild = useReviveBuildById()
  const statusSubscriptionDataResult = useBuildStatusUpdatedSubscription(buildId, {
    skip: !buildId,
  })

  // There is a short delay for the Deploy job to start in the backend
  // so the current build status may not reflect the "reviving state", unless
  // the build is already online. All other states (offline and deploying_failed) should
  // transition to "deploying", which we anticipate here to avoid flickering.
  let originalPermalinkStatus = revivingBuild?.permalinkStatus === 'online' ? 'online' : 'deploying'
  const permalinkStatus = get(
    statusSubscriptionDataResult,
    'data.build.permalinkStatus',
    originalPermalinkStatus,
  )

  useEffect(() => {
    if (buildId) {
      reviveBuild(buildId).then(({ build, userErrors }) => {
        // With this mutation, we can receive both an error message
        // *and* a build
        if (userErrors && userErrors.length) {
          setMutationError(userErrors[0].message)
        }
        setRevivingBuild(build)
      })
    }
  }, [buildId])

  let error
  let status = 'loading'

  if (buildNotFound) {
    error = `Deployment "${hostname}" was not found in our database.`
  }
  if (mutationError) {
    error = mutationError
  }
  if (permalinkStatus == 'deploying_failed') {
    error = 'We are unable to restore your deployment. Please try again later.'
  }

  if (reviveBuild) {
    status = permalinkStatus
  }

  if (error) {
    status = 'error'
  }

  return { status, error, build: revivingBuild }
}

const reviveBuildById = gql`
  mutation reviveBuild($id: ID!) {
    reviveBuild(id: $id) {
      build {
        id
        status
        permalinkStatus
        cloudAwsLambdaStatus
        consoleUrl
      }
      userErrors {
        message
        statusCode
      }
    }
  }
`

export const useReviveBuildById = () => {
  const [mutate] = useMutation(reviveBuildById)

  return async (id) => {
    const { data } = await mutate({
      variables: { id },
    })
    return data.reviveBuild
  }
}

export const buildUpdateSubscription = gql`
  subscription buildUpdated($buildId: ID!) {
    buildUpdated(buildId: $buildId) {
      updated {
        ...buildView
      }
      deleted
    }
  }
  ${BUILD_VIEW}
`

const BUILD_LIST_VIEW = gql`
  fragment BuildListView on FilterableBuild {
    id
    number
    permalinkUrl
    branch {
      id
      name
      edgePermalinkUrl
      latestCompletedBuild {
        id
      }
    }
    environmentVersion {
      id
      version
      configSource
      versionNotes
    }
    actor {
      ...ActorAvatar
    }
    environment {
      id
      name
      activeUrls
      activeBuild {
        id
      }
    }
    createdAt
    xdnVersion
    status
    permalinkStatus
    cloudAwsLambdaStatus
  }
  ${ACTOR_AVATAR_FRAGMENT}
`

const getBuilds = gql`
  query getBuilds(
    $propertyId: String
    $environmentId: String
    $dateFrom: ISO8601DateTime
    $dateTo: ISO8601DateTime
    $status: [String!]
    $xdnVersion: [String!]
    $offset: Int
    $orderBy: OrderByFilterableBuild = { number: desc }
  ) {
    builds(
      propertyId: $propertyId
      environmentId: $environmentId
      first: 20
      dateFrom: $dateFrom
      dateTo: $dateTo
      status: $status
      xdnVersion: $xdnVersion
      offset: $offset
      orderBy: [$orderBy]
    ) {
      nodes {
        ...BuildListView
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${BUILD_LIST_VIEW}
`

export const useGetBuildsQuery = ({
  propertyId,
  environmentId,
  dateFrom,
  dateTo,
  status,
  xdnVersion,
  orderBy,
}) => {
  const skip = !propertyId

  const results = useQuery(getBuilds, {
    variables: {
      propertyId,
      environmentId,
      dateFrom,
      dateTo,
      status,
      xdnVersion,
      orderBy,
    },
    notifyOnNetworkStatusChange: true,
    // because subscription is inactive while un-mounted, we refresh on remount
    fetchPolicy: 'cache-and-network',
    skip,
  })

  const { subscribeToMore, data, fetchMore } = results

  const showMoreBuilds = () => {
    fetchMore({
      variables: {
        propertyId,
        environmentId,
        dateFrom,
        dateTo,
        status,
        xdnVersion,
        orderBy,
        // always based the offset on the number of builds in the cache.  This will remain consistent even when
        // builds are deleted as long as they are removed from the cache.
        offset: data.builds.nodes.length,
      },
      updateQuery: ({ builds }, { fetchMoreResult }) => {
        if (!fetchMoreResult) return builds

        return {
          builds: {
            ...builds,
            nodes: [...builds.nodes, ...fetchMoreResult.builds.nodes],
            pageInfo: fetchMoreResult.builds.pageInfo,
          },
        }
      },
    })
  }

  useEffect(() => {
    if (skip) return
    return subscribeToMore({
      document: propertyBuildsSubscription,
      variables: { propertyId },
      updateQuery: createArrayUpdater(
        (prev) => prev.builds,
        ({ subscriptionData: { data } }) => {
          // Only append new builds belonging to the current environment
          let newBuilds = data.propertyBuildsUpdated.new
          if (environmentId && newBuilds) {
            newBuilds = newBuilds.filter((b) => get(b, 'environment.id') === environmentId)
          }
          return {
            ...data.propertyBuildsUpdated,
            new: newBuilds,
          }
        },
      ),
    })
  }, [propertyId, environmentId])
  return {
    ...results,
    showMoreBuilds,
  }
}

const getBuildStatuses = gql`
  query getBuildStatuses($propertyId: String!, $environmentId: String) {
    buildStatuses(propertyId: $propertyId, environmentId: $environmentId)
  }
`

const getBuildXdnVersions = gql`
  query getXdnVersions($propertyId: String!, $environmentId: String) {
    buildXdnVersions(propertyId: $propertyId, environmentId: $environmentId)
  }
`

export const useGetBuildFilterValuesLazyQuery = ({ propertyId, environmentId, type }) => {
  const skip = !propertyId

  const query = (() => {
    if (type === 'status') {
      return getBuildStatuses
    }

    if (type === 'xdnVersion') {
      return getBuildXdnVersions
    }

    throw new Error('Field does not exist on Build')
  })()

  return useLazyQuery(query, {
    variables: {
      propertyId,
      environmentId,
    },
    skip,
  })
}

const deletePropertyBuilds = gql`
  mutation deletePropertyBuilds($buildIds: [ID!]!, $propertyId: ID!) {
    deleteBuilds(buildIds: $buildIds, propertyId: $propertyId) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

export const useDeleteBuildsMutation = () => {
  const [mutate, mutationResult] = useMutation(deletePropertyBuilds)

  return [
    async (buildIds, propertyId) => {
      const { data } = await mutate({
        variables: { buildIds, propertyId },
      })
      return data.deleteBuilds
    },
    mutationResult,
  ]
}

const propertyBuildsSubscription = gql`
  subscription getPropertyBuildsUpdated($propertyId: ID!) {
    propertyBuildsUpdated(propertyId: $propertyId) {
      new {
        ...BuildListView
      }
      updated {
        ...BuildListView
      }
      deleted
    }
  }
  ${BUILD_LIST_VIEW}
`

export const createLogStreamToken = gql`
  mutation createToken($buildId: ID!) {
    createLogStreamToken(buildId: $buildId) {
      token
      serviceUrl
    }
  }
`

const retryBuild = gql`
  mutation retryBuild($buildId: ID!) {
    retryBuild(buildId: $buildId) {
      build {
        ...buildLink
      }
      userErrors {
        message
      }
    }
  }
  ${BUILD_LINK}
`
export const useRetryBuild = () => {
  const [mutate] = useMutation(retryBuild)
  const router = useRouter()
  const notifications = useNotifications()
  const buildPageUrl = useBuildUrl()

  return [
    async (build) => {
      const {
        data: {
          retryBuild: { build: promoteBuild, userErrors },
        },
        error,
      } = await mutate({
        variables: { buildId: build.id },
      })

      const firstError = error || (userErrors && userErrors[0])
      if (firstError) {
        notifications.error(firstError.message)
      } else {
        router.push(buildPageUrl(promoteBuild))
      }
    },
  ]
}
