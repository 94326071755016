import { ErrorOption } from 'react-hook-form'

export interface RestFetchOptions extends RequestInit {
  transformUrl?: boolean
}

export default async function restFetch(url: string, record?: any, options: RestFetchOptions = {}) {
  url = `${process.env.PROTOCOL}://${process.env.API_URL}${url}`

  const { transformUrl = true, headers = {}, ...others } = options
  let method = options.method || 'GET'

  if (record?.id && transformUrl) {
    url = `${url}/${record.id}`
  }
  if (record != null && !options.method) {
    method = record?.id ? 'PATCH' : 'POST'
  }

  headers['Content-Type'] = 'application/json'

  return fetch(url, {
    method,
    headers,
    credentials: 'include',
    body: record != null ? JSON.stringify(record) : undefined,
    ...others,
  })
}

export function downloadFile(url: string) {
  location.href = `${process.env.PROTOCOL}://${process.env.API_URL}${url}`
}

/**
 * Downloads a response with the content-disposition header from the rest api.
 * @param url
 */
export function restDownload(url: string) {
  location.href = `${process.env.PROTOCOL}://${process.env.API_URL}${url}`
}

/**
 * Passes errors returned from model validation to react-hook-form's setError function
 * @param res
 * @param setError
 */
export async function handleErrors(
  res: Response,
  setError: (field: string, options: ErrorOption) => void,
) {
  const { errors = {} }: { errors: Record<string, string[]> } = await res.json()

  Object.entries(errors).forEach(([field, messages]) => {
    setError(field, { message: messages[0] })
  })
}
