import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import type { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { useCurrentOrganization } from '../../../../hooks/useCurrentOrganization'
import { useDualQueryState } from '../../../../util'
import fetchAsmData from '../fetchAsmData'
import {
  ASM_PERMISSIONS,
  AsmPermission,
  AsmPermissionsBooleanMap,
  Collection,
  ExposureStatus,
  User,
} from '../types'

interface AsmContextDataType extends AsmPermissionsBooleanMap {
  collections: Collection[]

  /**
   * The slug of the current organization. Needed here to check
   * which organization this context belongs to.
   */
  currentOrganizationSlug: string

  /**
   * True if ASM is enabled for the current organization.
   */
  enabled: boolean

  /**
   * True if ASM should be shown by default after logging in.
   */
  showByDefault: boolean

  /**
   * The the array of all available permissions of currently logged in user.
   * @example ['asm_read', 'asm_scan']
   */
  permissions: AsmPermission[]

  /**
   * Returns true if the ASM has already been configured
   * for the current organization.
   */
  configured: boolean

  /**
   * Returns true if the context is still loading.
   */
  loading: boolean

  /**
   * The possible statuses of an exposure.
   */
  statuses: ExposureStatus[]

  /**
   * All of the users in the organization.
   */
  users: User[]

  /**
   * The maximum number of assets that can be under management.
   */
  max_assets_under_management: number

  /**
   * The number of assets currently under management.
   */
  assets_under_management: number

  /**
   * The active global collection filter.
   */
  globalCollectionFilter: string[]

  /**
   * The date when the exposure is due.
   */
  exposure_due_day_count: number

  /**
   * Returns true if the organization is allowed to scan Edgio IPs.
   */
  allow_edgio_ips: boolean

  /**
   * Sets the active global collection filter.
   */
  setGlobalCollectionFilter: (value: string[]) => void

  subscribed_to_threat_intel: boolean

  risk_score_boundary: number

  posture_score_grading_system: Object

  /**
   * Incremented each time a scan is triggered
   */
  scanCount: number

  /**
   * Updates the scan count, call this each time a scan is triggered.
   */
  setScanCount: (value: number | ((current: number) => number)) => void

  /**
   * Returns all available task types
   * for the current organization.
   */
  task_types: Array<{
    name: string
    value: string
  }>
}

export interface AsmContextType extends AsmContextDataType {
  reloadAsmContext: () => Promise<void>
}

const defaults = {
  collections: [],
  currentOrganizationSlug: null,
  enabled: false,
  showByDefault: false,
  permissions: [],
  configured: false,
  loading: true,
  statuses: [],
  users: [],
  canRead: false,
  canScan: false,
  canComment: false,
  canManage: false,
  reloadAsmContext: async () => {},
  max_assets_under_management: 0,
  assets_under_management: 0,
  exposure_due_day_count: 0,
  allow_edgio_ips: false,
  globalCollectionFilter: [],
  setGlobalCollectionFilter: () => {},
  subscribed_to_threat_intel: false,
  risk_score_boundary: 0,
  posture_score_grading_system: null,
  scanCount: 0, // incremented each time a scan is triggered
  setScanCount: () => {},
  task_types: [],
}

/**
 * The AsmContext
 * for all Asm related components.
 */
export const AsmContext = createContext<AsmContextType>(defaults)

// Paths that are filterable by collection_id / global collection filter
// Once the redirect happens, we can safely store the collection_id in localstorage.
// Also used to ACTUALLY define where it shows (GlobalCollectionFilter), and for the Link component query in AsmLinks
export const collectionIdFilterablePaths = [
  '/[organizationSlug]/security/asm/assets',
  '/[organizationSlug]/security/asm/exposures',
  '/[organizationSlug]/security/asm/cves',
  '/[organizationSlug]/security/asm/scans',
  '/[organizationSlug]/security/asm/protections',
  '/[organizationSlug]/security/asm/technologies',
  '/[organizationSlug]/security/asm/scans',
  '/[organizationSlug]/security/asm/threat_intel',
  '/[organizationSlug]/security/asm/getting_started',
  '/[organizationSlug]/security/asm/dashboard',
  '/[organizationSlug]/security/reports',
  '/[organizationSlug]/security/reports/[reportId]',
]

/**
 * The AsmContextProvider
 * provides context for all Asm related components.
 * @example const { loading, isConfigured, canManage } = useContext(AsmContext)
 */
export const AsmContextProvider = ({ children }: { children: ReactNode }) => {
  const { currentOrganization, loading: orgLoading } = useCurrentOrganization()
  const [scanCount, setScanCount] = useState<number>(0)
  const {
    queryState: { collection_id },
  } = useDualQueryState()
  const { pathname, replace } = useRouter()

  const getCollectionsArray = (id: string) => (id || '').split(',').filter((v) => v.length > 0)

  const [globalCollectionFilter, setGlobalCollectionFilter] = useState<string[]>([])

  useEffect(() => {
    // On initial page load, we clear the collection id - if the collection id is inside of query, it will be re-set in the next useEffect
    localStorage.removeItem('asm_collection_id_filter')
  }, [])

  useEffect(() => {
    // We know that when we visit "collection-filterable pages", the collection_id query param will be set properly - therefore we can re-parse it, save to localstorage.
    // We invert the check - eg. collection filter will be visible only in top level asm pages.
    if (collectionIdFilterablePaths.includes(pathname)) {
      const collections = getCollectionsArray(collection_id as string)
      if (collections.length > 0) {
        localStorage.setItem('asm_collection_id_filter', collections.join(','))
        setGlobalCollectionFilter(collections)
      } else {
        localStorage.removeItem('asm_collection_id_filter')
        setGlobalCollectionFilter([])
      }
    }
  }, [pathname, collection_id])

  const [context, setContext] = useState<AsmContextDataType>({
    ...defaults,
    globalCollectionFilter,
    setGlobalCollectionFilter,
  })

  useEffect(() => {
    setContext((context) => ({ ...context, globalCollectionFilter }))
  }, [globalCollectionFilter])

  const fetchContext = useCallback(async () => {
    try {
      setContext((context) => ({ ...context, loading: true }))

      const res = await fetchAsmData(`/context?organization_id=${currentOrganization.id}`)

      if (res.ok) {
        const data = await res.json()

        // Create a boolean map
        // of all available permissions of currently logged in user
        // @example { canRead: true, canScan: false... }
        const permissions = Object.fromEntries(
          Object.entries(ASM_PERMISSIONS).map(([key, value]) => [
            `can${key}`,
            data.permissions.includes(value),
          ]),
        ) as AsmPermissionsBooleanMap

        setContext((context) => ({
          ...context,
          ...data,
          ...permissions,
          currentOrganizationSlug: currentOrganization.slug,
        }))
      }
      if (res.status === 403) {
        // When user switch to organization where he/she has no permissions,
        // the context should be reset to defaults,
        // so ASM disappears from the sidebar.
        setContext(defaults)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setContext((context) => ({ ...context, loading: false }))
    }
  }, [currentOrganization])

  // Fetch available permissions of currently logged in user
  useEffect(() => {
    if (currentOrganization) {
      fetchContext()
    }
  }, [currentOrganization])

  useEffect(() => {
    if (orgLoading || context.loading) return
    if (!currentOrganization) return
    if (currentOrganization && currentOrganization.slug !== context.currentOrganizationSlug) {
      return
    }

    const permissionDeniedPath = `/${context.currentOrganizationSlug}/security/asm/permission_denied`

    if (
      pathname.includes('/security/asm') &&
      pathname !== permissionDeniedPath &&
      !context.loading &&
      (context.canRead === false || !context.enabled)
    ) {
      replace(permissionDeniedPath)
    }
  }, [context, pathname, currentOrganization, orgLoading])

  const asmContext = useMemo(() => {
    return { ...context, reloadAsmContext: fetchContext, scanCount, setScanCount }
  }, [context, fetchContext, scanCount, setScanCount])

  return <AsmContext.Provider value={asmContext}>{children}</AsmContext.Provider>
}

export default AsmContext
