import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import HelpIcon from '@mui/icons-material/HelpOutline'
import { IconProps, Link, Theme } from '@mui/material'
import { Button } from 'ui'
import { Colors } from 'ui'

type Props = {
  title: string
  Icon?: React.ComponentType<IconProps>
  subtitle?: string | React.ReactNode
  docsLink?: string
  toolbarItems?: React.ReactNode[]
  style?: any
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    padding: 24,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    marginBottom: theme.spacing(2),
  },
  leftContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  iconContainer: {
    width: 40,
    height: 40,
    background: `${theme.palette.mode === 'dark' ? Colors.grey[100] : Colors.grey.t92}`,
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
  },
  titleContainer: {
    fontWeight: 500,
    fontSize: 22,
    lineHeight: '22px',
    paddingBottom: 6,
  },
  subtitleContainer: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '12px',
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginLeft: 'auto',
  },
}))

const DOCS_ROOT = 'https://docs.edg.io/applications/v7/'

export const PageHeader = ({ title, subtitle, Icon, docsLink, toolbarItems, style }: Props) => {
  const { classes } = useStyles()
  const fullDocsUrl = useMemo(() => {
    if (!docsLink) return

    return docsLink?.startsWith('http') ? docsLink : `${DOCS_ROOT}${docsLink}`
  }, [docsLink])

  return (
    <div className={classes.root} style={style}>
      <div className={classes.leftContainer}>
        {Icon && (
          <div className={classes.iconContainer}>
            <Icon style={{ width: 24, height: 24 }} />
          </div>
        )}
        <div className={classes.textContainer}>
          <div className={classes.titleContainer}>
            <span>{title}</span>
          </div>
          {subtitle && (
            <div className={classes.subtitleContainer}>
              <span>{subtitle}</span>
            </div>
          )}
        </div>
      </div>
      <div className={classes.toolbar}>
        {toolbarItems && toolbarItems.map((item) => item)}
        {fullDocsUrl && (
          <Link href={fullDocsUrl} target="_blank">
            <Button color="secondary" startIcon={<HelpIcon />}>
              Docs
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}
