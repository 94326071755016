import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { HOME_SNAPSHOT_VIEW } from '../build/BuildHomeSnapshotPanel'
import { gql, useMutation, useQuery } from '@apollo/client'
import produce from 'immer'
import { propertyUrl } from '../authenticatedRoutes'
import { PAGE_SIZE } from '../constants'
import { useCurrentEnvironment } from '../hooks/useCurrentEnvironment'
import { useCurrentOrganization } from '../hooks/useCurrentOrganization'
import { useCurrentProperty } from '../hooks/useCurrentProperty'
import { getEnvironments } from '../property/propertyQueries'
import { CERT_REQUEST_VIEW, CERT_VIEW } from './cert/certQueries'
import { ENV_REDIRECT_VIEW } from './redirects/RedirectQueries'

const ENVIRONMENT_BUILD_CARD_VIEW = gql`
  fragment EnvironmentBuildCardView on Build {
    id
    number
    xdnVersion
    cloudRuntime
    status
    inProgress
    finishedAt
    createdAt
    permalinkUrl
    homeSnapshot {
      ...homeSnapshotView
    }
    environmentVersion {
      id
      version
    }
    branch {
      id
      name
    }
  }
  ${HOME_SNAPSHOT_VIEW}
`

const ENVIRONMENT_VERSION_LIST_VIEW = gql`
  fragment EnvironmentVersionListView on EnvironmentVersion {
    id
    version
    draft
    configSource
    dirty
    configSource
    createdAt
  }
`

export const DOMAIN_VIEW = gql`
  fragment DomainView on Domain {
    id
    name
    createdAt
    isApex
    environment {
      production
    }
    cert {
      id
      expiration
      status
      generated
      missingDomains
    }
  }
`

const PUBLIC_DOMAIN_VIEW = gql`
  fragment PublicDomainView on PublicDomain {
    id
    name
    createdAt
    isApex
  }
`
const VALIDATE_DOMAIN_VIEW = gql`
  fragment ValidateDomainView on PublicDomain {
    ...PublicDomainView
    validTlsCaa
    validTlsCname
    validDns
  }
  ${PUBLIC_DOMAIN_VIEW}
`

const ENVIRONMENT_VERSION_VIEW = gql`
  fragment EnvironmentVersionView on EnvironmentVersion {
    ...EnvironmentVersionListView
    lockVersion
    versionNotes
    domains {
      nodes {
        ...PublicDomainView
      }
    }
    configSource
    rules
    origins
    hostnames {
      hostname
      defaultOriginName
    }
    safeEdgeHostnames {
      hostname
      defaultOriginName
    }
    edgeFunctionsSources
    edgeFunctionsUsedInRules
    edgeFunctionInitScript
    activeEdgeFunctionsCompilationRequest {
      id
      status
      compilationErrors {
        text
        location {
          file
          line
          column
          length
          lineText
        }
      }
      edgeFunctionsSources
      edgeFunctionInitScript
    }
    edgeFunctionsCompilerVersion
    rtld
    extraRules
    experiments
  }
  ${ENVIRONMENT_VERSION_LIST_VIEW}
  ${PUBLIC_DOMAIN_VIEW}
`

const ENVIRONMENT_VIEW = gql`
  fragment EnvironmentView on Environment {
    id
    name
    default
    defaultDomainName
    dnsDomainName
    pciDnsDomainName
    nonPciDnsDomainName
    apexIps
    mccHexId
    idsTenantId
    permalinksHexId
    permalinksTenantId
    coreWebVitalsLoggingTokenOverride
    coreWebVitalsLoggingToken
    allowlistIps {
      ipAddresses
      sourceName
    }
    activeUrls
    onlyMaintainersCanDeploy
    safeTokenAuthKeys
    tokenAuthKeysDirty
    disableServerlessShield
    preserveCache
    production
    pciCompliance
    activeClusters
    property {
      maxMemorySize
    }
    activeCert {
      ...CertView
    }
    lastCert {
      ...CertView
    }
    sunfishCertId
    lastCertRequest {
      ...CertRequestView
    }
    awsAccount {
      id
      name
      accessLogsBucketName
      free
      accessLogsAwsAccessKeyId
      accessLogsAwsSecretAccessKey
    }
    activeVersion {
      ...EnvironmentVersionListView
    }
    draftVersion {
      ...EnvironmentVersionListView
    }
    environmentVersions {
      nodes {
        ...EnvironmentVersionListView
      }
    }
    latestBuild {
      ...EnvironmentBuildCardView
    }
    activeBuild {
      ...EnvironmentBuildCardView
    }
    activeDomains {
      ...DomainView
    }
    draftDomains {
      ...PublicDomainView
    }
    redirectDefaultStatus
    redirectDeployedDefaultStatus
    redirectsDirty
    httpRequestLogging
    supportSchemaOrigins
    rsyncUrl
  }
  ${ENVIRONMENT_VERSION_LIST_VIEW}
  ${ENVIRONMENT_BUILD_CARD_VIEW}
  ${DOMAIN_VIEW}
  ${PUBLIC_DOMAIN_VIEW}
  ${CERT_VIEW}
  ${CERT_REQUEST_VIEW}
`

export const getEnvironment = gql`
  query environmentByName($organizationSlug: String!, $propertySlug: String!, $name: String!) {
    environmentByName(
      organizationSlug: $organizationSlug
      propertySlug: $propertySlug
      name: $name
    ) {
      ...EnvironmentView
    }
    currentActorEnvironmentRole(
      organizationSlug: $organizationSlug
      propertySlug: $propertySlug
      name: $name
    ) {
      actions {
        action
        subject
      }
      role
    }
  }
  ${ENVIRONMENT_VIEW}
`

const getEnvironmentVersions = gql`
  query environmentVersions(
    $organizationSlug: String!
    $propertySlug: String!
    $name: String!
    $first: Int
    $offset: Int
  ) {
    environmentByName(
      organizationSlug: $organizationSlug
      propertySlug: $propertySlug
      name: $name
    ) {
      id
      environmentVersions(first: $first, offset: $offset, orderBy: [{ version: desc }]) {
        nodes {
          ...EnvironmentVersionListView
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
  ${ENVIRONMENT_VERSION_LIST_VIEW}
`

export const useGetEnvironmentVersions = (variables) => {
  const results = useQuery(getEnvironmentVersions, {
    variables: { first: PAGE_SIZE, ...variables },
    notifyOnNetworkStatusChange: true,
    skip: !variables.organizationSlug || !variables.propertySlug || !variables.name,
  })

  const { data, fetchMore } = results

  const showMoreEnvironmentVersions = () => {
    fetchMore({
      variables: {
        ...variables,
        offset: data.environmentByName.environmentVersions.nodes.length,
        first: PAGE_SIZE,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        const merged = {
          environmentByName: {
            ...prev.environmentByName,
            environmentVersions: {
              nodes: [
                ...prev.environmentByName.environmentVersions.nodes,
                ...fetchMoreResult.environmentByName.environmentVersions.nodes,
              ],
              pageInfo: fetchMoreResult.environmentByName.environmentVersions.pageInfo,
            },
          },
        }

        return merged
      },
    })
  }

  return {
    ...results,
    showMoreEnvironmentVersions,
  }
}

export const validateDomain = gql`
  query domain($domainId: ID!) {
    domain(domainId: $domainId) {
      ...ValidateDomainView
    }
  }
  ${VALIDATE_DOMAIN_VIEW}
`

const environmentUpdatedSubscription = gql`
  subscription environmentUpdated($environmentId: ID!) {
    environmentUpdated(environmentId: $environmentId) {
      updated {
        ...EnvironmentView
      }
    }
  }
  ${ENVIRONMENT_VIEW}
`

export const useGetEnvironment = (variables) => {
  const { subscribeToMore, loading, data, error } = useQuery(getEnvironment, {
    variables,
    skip: !variables.organizationSlug || !variables.propertySlug || !variables.name,
  })

  const environment = data && data.environmentByName
  const currentActorEnvironmentRole = data && data.currentActorEnvironmentRole

  useEffect(() => {
    if (!environment) return

    return subscribeToMore({
      document: environmentUpdatedSubscription,
      variables: { environmentId: environment.id },
    })
  }, [environment && environment.id])

  return {
    loading,
    environment: data && data.environmentByName,
    currentActorEnvironmentRole,
    error,
  }
}

export const useEnvironment = () => {
  const router = useRouter()
  const { organizationSlug, propertySlug, environmentName } = router.query

  const { loading, environment, error } = useGetEnvironment({
    organizationSlug,
    propertySlug,
    name: environmentName,
  })

  return { loading, environment, error }
}

export const updateEnvironment = gql`
  mutation updateEnvironment($environment: UpdateEnvironmentAttributes!) {
    updateEnvironment(environment: $environment) {
      environment {
        ...EnvironmentView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ENVIRONMENT_VIEW}
`

export const useUpdateEnvironmentMutation = () => {
  const [mutate, mutationResult] = useMutation(updateEnvironment)

  return [
    async (environment) => {
      const { data } = await mutate({
        variables: { environment },
      })

      return data.updateEnvironment
    },
    mutationResult,
  ]
}

export const toggleHttpLogging = gql`
  mutation toggleHttpRequestLogging($environmentId: ID!, $httpRequestLogging: Boolean!) {
    toggleHttpRequestLogging(
      environmentId: $environmentId
      httpRequestLogging: $httpRequestLogging
    ) {
      build {
        id
        number
      }
      userErrors {
        message
        path
      }
    }
  }
`

export const useToggleHttpLogging = () => {
  const [mutate, mutationResult] = useMutation(toggleHttpLogging)

  return [
    async (environmentId, httpRequestLogging) => {
      const { data } = await mutate({
        variables: { environmentId, httpRequestLogging },
      })

      return data.toggleHttpRequestLogging
    },
    mutationResult,
  ]
}

export const togglePciCompliance = gql`
  mutation adminTogglePciCompliance($environmentId: ID!, $pciCompliance: Boolean!) {
    adminTogglePciCompliance(environmentId: $environmentId, pciCompliance: $pciCompliance) {
      environment {
        pciCompliance
      }
      userErrors {
        message
        path
      }
    }
  }
`

export const useTogglePciCompliance = () => {
  const [mutate, mutationResult] = useMutation(togglePciCompliance)

  return [
    async (environmentId, pciCompliance) => {
      const { data } = await mutate({
        variables: { environmentId, pciCompliance },
      })

      return data.adminTogglePciCompliance
    },
    mutationResult,
  ]
}

export const removeActiveCertFromOtherCluster = gql`
  mutation adminRemoveActiveCertFromOtherCluster($environmentId: ID!) {
    adminRemoveActiveCertFromOtherCluster(environmentId: $environmentId) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

export const useRemoveActiveCertFromOtherCluster = () => {
  const [mutate, mutationResult] = useMutation(removeActiveCertFromOtherCluster)

  return [
    async (environmentId) => {
      const { data } = await mutate({
        variables: { environmentId },
      })

      return data.adminRemoveActiveCertFromOtherCluster
    },
    mutationResult,
  ]
}

export const getEnvironmentVersion = gql`
  query environmentVersion($id: ID!) {
    environmentVersion(id: $id) {
      ...EnvironmentVersionView
    }
  }
  ${ENVIRONMENT_VERSION_VIEW}
`
export const getEnvironmentVersionByNumber = gql`
  query environmentVersionByNumber($environmentId: ID!, $version: Int!) {
    environmentVersionByNumber(environmentId: $environmentId, version: $version) {
      ...EnvironmentVersionView
    }
  }
  ${ENVIRONMENT_VERSION_VIEW}
`

const environmentVersionUpdatedSubscription = gql`
  subscription environmentVersionUpdated($environmentVersionId: ID!) {
    environmentVersionUpdated(environmentVersionId: $environmentVersionId) {
      updated {
        ...EnvironmentVersionView
      }
    }
  }
  ${ENVIRONMENT_VERSION_VIEW}
`

export const useGetEnvironmentVersion = (variables, { subscribeToDraftChanges = false } = {}) => {
  const { loading, data, error, subscribeToMore, refetch } = useQuery(
    variables.version ? getEnvironmentVersionByNumber : getEnvironmentVersion,
    {
      variables,
      // Always refetch environment data, until we implement subscription
      // fetchPolicy: 'network-only',
      // Skip request when the ID is not provided
      skip:
        variables.id === undefined &&
        (variables.version === undefined || variables.environmentId === undefined),
    },
  )

  const environmentVersion = data && (data.environmentVersion || data.environmentVersionByNumber)

  useEffect(() => {
    if (!environmentVersion) return

    return subscribeToMore({
      document: environmentVersionUpdatedSubscription,
      variables: { environmentVersionId: environmentVersion.id },
      updateQuery: (prev, { subscriptionData }) => {
        const [updatedRecord] = subscriptionData.data?.environmentVersionUpdated?.updated || []

        if (!updatedRecord) return prev
        // In general, we don't enable subscriptions on drafts as it makes concurrent editing more surprising.
        // Without subscription: if one user updates rules and another one updates rules too,
        // it will raise a conflict error thanks to the lockVersion field.  With subscription it would
        // silently override.
        if (!subscribeToDraftChanges && updatedRecord.draft) return prev

        return {
          environmentVersion: updatedRecord,
        }
      },
    })
  }, [environmentVersion?.id])

  return {
    loading,
    refetch,
    environmentVersion,
    error,
  }
}

export const updateEnvironmentVersionDraftMutation = gql`
  mutation updateEnvironmentVersionDraft($environmentVersion: UpdateEnvironmentVersionAttributes!) {
    updateEnvironmentVersionDraft(environmentVersion: $environmentVersion) {
      environmentVersion {
        ...EnvironmentVersionView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ENVIRONMENT_VERSION_VIEW}
`

export const useUpdateEnvironmentVersionDraft = () => {
  const [mutate, mutationResult] = useMutation(updateEnvironmentVersionDraftMutation)

  return [
    async (environmentVersion) => {
      const { data } = await mutate({
        variables: {
          environmentVersion,
        },
        // TODO: re-introduce once we're not sending another mutation while another is in flight
        update: (
          cache,
          {
            data: {
              updateEnvironmentVersionDraft: { environmentVersion },
            },
          },
        ) => {
          if (environmentVersion?.lockVersion) {
            cache.updateQuery(
              { query: getEnvironmentVersion, variables: { id: environmentVersion.id } },
              produce((draft) => {
                draft.environmentVersion.lockVersion = environmentVersion.lockVersion
              }),
            )
          }
        },
      })

      return data.updateEnvironmentVersionDraft
    },
    mutationResult,
  ]
}

const createEnvironment = gql`
  mutation createEnvironment($environment: CreateEnvironmentAttributes!, $copyEnvironment: ID) {
    createEnvironment(environment: $environment, copyEnvironment: $copyEnvironment) {
      environment {
        ...EnvironmentView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ENVIRONMENT_VIEW}
`

export const useCreateEnvironmentMutation = () => {
  const [mutate, mutationResult] = useMutation(createEnvironment)

  return [
    async (propertyId, copyEnvId, { name, onlyMaintainersCanDeploy, production }) => {
      const { data } = await mutate({
        variables: {
          environment: { propertyId, name, onlyMaintainersCanDeploy, production },
          copyEnvironment: copyEnvId,
        },
        // If it works, refresh the list
        refetchQueries: [{ query: getEnvironments, variables: { propertyId } }],
        // Need to await that list is refreshed as usually the next step
        // is to navigate to the Environment page
        awaitRefetchQueries: true,
      })

      return data.createEnvironment
    },
    mutationResult,
  ]
}

const deleteEnvironment = gql`
  mutation deleteEnvironment($id: ID!) {
    deleteEnvironment(id: $id)
  }
`

export const useDeleteEnvironmentMutation = () => {
  const [mutate, mutationResult] = useMutation(deleteEnvironment)
  const { currentOrganization } = useCurrentOrganization()
  const { currentProperty } = useCurrentProperty()
  const router = useRouter()

  return [
    async (id) => {
      const { data } = await mutate({
        variables: { id },
        // If it works, refresh the list. Suboptimal but lighter code
        refetchQueries: [{ query: getEnvironments, variables: { propertyId: currentProperty.id } }],
        update: async (cache) => {
          const normalizedId = cache.identify({
            id,
            __typename: 'Environment',
          })
          await router.push(propertyUrl(currentOrganization, currentProperty))
          cache.evict({ id: normalizedId })
          cache.gc()
        },

        awaitRefetchQueries: true,
      })
      return data.deleteEnvironment
    },
    mutationResult,
  ]
}

const setProductionEnvironment = gql`
  mutation setProductionEnvironment($id: ID!) {
    setProductionEnvironment(id: $id) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useSetProductionEnvironment = () => {
  const [mutate, mutationResult] = useMutation(setProductionEnvironment)
  const { currentProperty } = useCurrentProperty()

  return [
    async (id) => {
      const { data } = await mutate({
        variables: { id },
        refetchQueries: [{ query: getEnvironments, variables: { propertyId: currentProperty.id } }],
      })
      return data.setProductionEnvironment
    },
    mutationResult,
  ]
}

const activateEnvironmentVersion = gql`
  mutation activateEnvironmentVersion($id: ID!, $purgeCacheOnDeploy: Boolean) {
    activateEnvironmentVersion(id: $id, purgeCacheOnDeploy: $purgeCacheOnDeploy) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useActivateEnvironmentVersion = () => {
  const [mutate, mutationResult] = useMutation(activateEnvironmentVersion)
  const { currentOrganization } = useCurrentOrganization()
  const { currentProperty } = useCurrentProperty()
  const { currentEnvironment } = useCurrentEnvironment()

  return [
    async (environmentVersionId, purgeCacheOnDeploy) => {
      const { data } = await mutate({
        variables: { id: environmentVersionId, purgeCacheOnDeploy: purgeCacheOnDeploy },
        // TODO: refator duplicated refresh code
        refetchQueries: [
          {
            query: getEnvironment,
            variables: {
              organizationSlug: currentOrganization.slug,
              propertySlug: currentProperty.slug,
              name: currentEnvironment.name,
            },
          },
          {
            query: getEnvironmentVersion,
            variables: { id: environmentVersionId },
          },
        ],
        awaitRefetchQueries: true,
      })
      return data.activateEnvironmentVersion
    },
    mutationResult,
  ]
}

const revertDraftChanges = gql`
  mutation revertDraftChanges($environmentVersionId: ID!) {
    revertDraftChanges(environmentVersionId: $environmentVersionId) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useRevertDraftChanges = () => {
  const [mutate, mutationResult] = useMutation(revertDraftChanges)
  const { currentOrganization } = useCurrentOrganization()
  const { currentProperty } = useCurrentProperty()
  const { currentEnvironment } = useCurrentEnvironment()

  return [
    async (environmentVersionId) => {
      const { data } = await mutate({
        variables: { environmentVersionId },
        // TODO: refator duplicated refresh code
        refetchQueries: [
          {
            query: getEnvironment,
            variables: {
              organizationSlug: currentOrganization.slug,
              propertySlug: currentProperty.slug,
              name: currentEnvironment.name,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
      return data.revertEnvironmentDraftChanges
    },
    mutationResult,
  ]
}

export const useUploadRedirectsMutation = () =>
  useMutation(gql`
    mutation uploadRedirects($environmentId: ID!, $file: Upload!, $override: Boolean!) {
      uploadEnvironmentRedirects(environmentId: $environmentId, file: $file, override: $override) {
        userErrors {
          message
          path
        }
      }
    }
  `)

export const useSaveRedirectsMutations = () =>
  useMutation(gql`
    mutation saveRedirects(
      $environmentId: ID!
      $id: ID
      $from: String!
      $to: String!
      $status: Int
      $forwardQueryString: Boolean
    ) {
      saveEnvironmentRedirect(
        environmentRedirect: {
          environmentId: $environmentId
          id: $id
          from: $from
          to: $to
          status: $status
          forwardQueryString: $forwardQueryString
        }
      ) {
        environmentRedirect {
          ...EnvRedirectView
        }
        userErrors {
          path
          message
        }
      }
    }
    ${ENV_REDIRECT_VIEW}
  `)

export const useDeleteRedirectsMutations = () =>
  useMutation(gql`
    mutation deleteRedirects($ids: [ID!]!) {
      deleteEnvironmentRedirects(ids: $ids)
    }
  `)

export const useSaveRedirectsDefaultStatusMutations = () =>
  useMutation(gql`
    mutation saveRedirectsDefaultStatus($environmentId: ID!, $defaultStatus: Int!) {
      saveEnvironmentRedirectDefaultStatus(
        environmentId: $environmentId
        defaultStatus: $defaultStatus
      ) {
        userErrors {
          message
          path
        }
      }
    }
  `)

const ENVIRONMENT_EDGE_CONTROL_STAFF_RULES_VIEW = gql`
  fragment EnvironmentEdgeControlStaffRulesView on EnvironmentEdgeControlStaffRules {
    id
    config
  }
`

const SET_EDGE_CONTROL_STAFF_RULES = gql`
  mutation setEdgeControlStaffRules($environmentId: ID!, $config: JSON!) {
    setEdgeControlStaffRules(environmentId: $environmentId, config: $config) {
      environmentStaffRules {
        ...EnvironmentEdgeControlStaffRulesView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${ENVIRONMENT_EDGE_CONTROL_STAFF_RULES_VIEW}
`

export const useSetEdgeControlStaffRules = () => {
  const [mutate, mutationResult] = useMutation(SET_EDGE_CONTROL_STAFF_RULES)
  const { currentEnvironment } = useCurrentEnvironment()

  return [
    async (config) => {
      const { data } = await mutate({
        variables: { environmentId: currentEnvironment.id, config },
      })
      return data.setEdgeControlStaffRules
    },
    mutationResult,
  ]
}

const READ_EDGE_CONTROL_STAFF_RULES = gql`
  query edgeControlStaffRules($environmentId: ID!) {
    edgeControlStaffRules(environmentId: $environmentId) {
      ...EnvironmentEdgeControlStaffRulesView
    }
  }
  ${ENVIRONMENT_EDGE_CONTROL_STAFF_RULES_VIEW}
`

export const useReadEdgeControlStaffRules = (options = {}) => {
  const { currentEnvironment } = useCurrentEnvironment()
  const queryHookResult = useQuery(READ_EDGE_CONTROL_STAFF_RULES, {
    ...options,
    variables: { environmentId: currentEnvironment.id },
  })

  return {
    ...queryHookResult,
    config: queryHookResult.data?.edgeControlStaffRules?.config,
  }
}
