import { ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'
import { CardActions, CardContent, CardHeader, Card as MuiCard, Theme } from '@mui/material'

export interface CardProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode
  subtitle?: ReactNode
  titleAction?: ReactNode
  cardActions?: ReactNode
  height?: number
  minHeight?: number
  maxHeight?: number
  children?: React.ReactNode
  noPadding?: boolean
  overflowHidden?: boolean
  customClasses?: any
  sx?: any
  'data-qa'?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    '.MuiGrid-item > &': {
      height: '100%',
    },
    backgroundImage: 'none',
    boxShadow: 'none',
  },
  rootNoPadding: {
    padding: 0,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'relative',

    '& > .search-result': {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(-3),
      width: `calc(100% + ${theme.spacing(4)})`,
      '& .MuiTableContainer-root': {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-start',
        borderLeftWidth: `0 !important`,
        borderRightWidth: 0,
        borderRadius: 0,
      },
    },
  },
  contentOverflowHidden: {
    overflow: 'hidden',
  },
  contentNoPadding: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  header: {
    paddingBottom: 0,
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1,
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    '.MuiCardHeader-root &': {
      fontSize: theme.typography.caption.fontSize,
      color: theme.typography.caption.color,
      marginTop: 0,
    },
  },
  titleAction: {
    padding: theme.spacing(1),
  },
  avatar: {},
  cardActions: {
    padding: `${theme.spacing(2)} 0 0 0 `,
  },
}))

export default function Card({
  title,
  subtitle,
  titleAction,
  cardActions,
  children,
  height,
  minHeight,
  maxHeight,
  noPadding,
  overflowHidden = Boolean(height || maxHeight),
  customClasses,
  sx,
  style,
  'data-qa': dataQa,
}: CardProps) {
  const { classes, cx } = useStyles(undefined, {
    props: {
      classes: customClasses,
    },
  })

  return (
    <MuiCard
      className={cx({ [classes.root]: true, [classes.rootNoPadding]: noPadding })}
      style={{ height, maxHeight, minHeight, ...style }}
      sx={sx}
      elevation={10}
      data-qa={dataQa}
    >
      {title && (
        <CardHeader
          title={title}
          subheader={subtitle}
          action={titleAction}
          classes={{
            root: classes.header,
            title: classes.title,
            subheader: classes.subtitle,
            action: classes.titleAction,
            content: classes.content,
            avatar: classes.avatar,
          }}
        />
      )}
      {children && (
        <CardContent
          className={cx({
            [classes.content]: true,
            [classes.contentNoPadding]: noPadding,
            [classes.contentOverflowHidden]: overflowHidden,
          })}
        >
          {children}
          {cardActions && <CardActions className={classes.cardActions}>{cardActions}</CardActions>}
        </CardContent>
      )}
    </MuiCard>
  )
}

Card.defaultProps = {
  padContent: false,
  minHeight: 0,
}
