import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  fullscreen: {
    top: 72,
    left: theme.spacing(-1),
    height: 'calc(100vh - 128px)',
    width: '100%',
    '.tabpanels &': {
      top: 0,
      height: 'calc(100vh - 275px)',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  transparentBackground: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
}))

export default function Loading({
  variant = 'fullscreen',
  customClasses = {},
  fullscreen = true,
  className = '',
  paper = false,
  transparentBackground = false,
  overlay = false,
}) {
  const { classes, cx } = useStyles(undefined, {
    props: {
      classes: customClasses,
    },
  })

  if (variant !== 'fullscreen') {
    fullscreen = false
  }

  return (
    <div
      data-loading={true}
      className={cx({
        [classes.root]: true,
        [classes.fullscreen]: fullscreen,
        [classes[variant]]: true,
        [classes.paper]: paper,
        [classes.transparentBackground]: transparentBackground,
        [classes.overlay]: overlay,
        [className]: true,
      })}
    >
      <CircularProgress />
    </div>
  )
}
